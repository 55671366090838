import { createRouter, createWebHistory } from "vue-router";
import authService from "../services/authService";

const routes = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      guest: true,
    },
  },

  {
    path: "/contul-meu",
    name: "dashboard.show",
    component: () =>
      import(/* webpackChunkName: "dashboard.show" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/comenzi",
    name: "orders.index",
    component: () =>
      import(/* webpackChunkName: "orders.index" */ "../views/OrdersIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: "Dashboard", href: "/contul-meu", current: false },
        { name: "Comenzi", href: "/comenzi", current: true },
      ],
    },
  },

  {
    path: "/comenzi/:orderId",
    props: true,
    name: "orders.show",
    component: () =>
      import(/* webpackChunkName: "orders.show" */ "../views/OrdersShow.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Comenzi", href: "/comenzi", current: false },
          {
            name: `Comanda #${route.params.orderId}`,
            href: `/comenzi/${route.params.orderId}`,
            current: true,
          },
        ];
      },
    },
  },

  {
    path: "/comenzi-vanzatori",
    name: "vendorOrders.index",
    component: () =>
      import(/* webpackChunkName: "vendorOrders.index" */ "../views/VendorOrdersIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: "Dashboard", href: "/contul-meu", current: false },
        { name: "Comenzi vanzatori", href: "/comenzi-vanzatori", current: true },
      ],
    },
  },
  {
    path: "/comenzi-vanzatori/:id",
    props: true,
    name: "vendorOrders.show",
    component: () =>
      import(/* webpackChunkName: "vendorOrders.show" */ "../views/VendorOrdersShow.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Comenzi vanzatori", href: "/comenzi-vanzatori", current: false },
          {
            name: `Comanda vanzator #${route.params.id}`,
            href: `/comenzi-vanzatori/${route.params.id}`,
            current: true,
          },
        ];
      },
    },
  },

  {
    path: "/plati",
    name: "payments.index",
    component: () =>
      import(/* webpackChunkName: "payments.index" */ "../views/PaymentsIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: "Dashboard", href: "/contul-meu", current: false },
        { name: "Plati", href: "/plati", current: true },
      ],
    },
  },
  {
    path: "/plati/:paymentId",
    props: true,
    name: "payments.show",
    component: () =>
      import(/* webpackChunkName: "payments.show" */ "../views/PaymentsShow.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Plati", href: "/plati", current: false },
          {
            name: `Plata #${route.params.paymentId}`,
            href: `/plati/${route.params.paymentId}`,
            current: true,
          },
        ];
      },
    },
  },

  {
    path: "/plati-vanzatori",
    name: "vendorPayments.index",
    component: () =>
      import(/* webpackChunkName: "vendorPayments.index" */ "../views/VendorPaymentsIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: "Dashboard", href: "/contul-meu", current: false },
        { name: "Plati vanzatori", href: "/plati-vanzatori", current: true },
      ],
    },
  },

  // I think this is not used atm
  {
    path: "/plati-vanzatori/:id",
    props: true,
    name: "vendorPayments.show",
    component: () =>
      import(/* webpackChunkName: "vendorPayments.show" */ "../views/VendorOrdersShow.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Comenzi vanzatori", href: "/plati-vanzatori", current: false },
          {
            name: `Plati vanzatori #${route.params.id}`,
            href: `/plati-vanzatori/${route.params.id}`,
            current: true,
          },
        ];
      },
    },
  },

  {
    path: "/retrageri",
    name: "vendorWithdrawals.index",
    component: () =>
      import(/* webpackChunkName: "vendorWithdrawals.index" */ "../views/VendorWithdrawalsIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: "Dashboard", href: "/contul-meu", current: false },
        { name: "Retrageri vanzatori", href: "/retrageri", current: true },
      ],
    },
  },

  {
    path: "/comisioane",
    name: "fees.index",
    component: () =>
      import(/* webpackChunkName: "fees.index" */ "../views/FeesIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: "Dashboard", href: "/contul-meu", current: false },
        { name: "Comisioane", href: "/comisioane", current: true },
      ],
    },
  },
  {
    path: "/comisioane/adauga",
    name: "fees.create",
    component: () =>
      import(/* webpackChunkName: "fees.create" */ "../views/FeesCreate.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: "Dashboard", href: "/contul-meu", current: false },
        { name: "Comisioane", href: "/comisioane", current: false },
        { name: "Adauga comision", href: "/comisioane/adauga", current: true },
      ],
    },
  },

  {
    path: "/vanzatori",
    name: "vendors.index",
    component: () =>
      import(/* webpackChunkName: "vendors.index" */ "../views/VendorsIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: "Dashboard", href: "/contul-meu", current: false },
        { name: "Vanzatori", href: "/vanzatori", current: true },
      ],
    },
  },
  {
    path: "/vanzatori/:id",
    props: true,
    name: "vendors.show",
    component: () =>
      import(/* webpackChunkName: "vendors.show" */ "../views/VendorsShow.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Vanzatori", href: "/vanzatori", current: false },
          {
            name: `Vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}`,
            current: true,
          },
        ];
      },
    },
  },

  {
    path: "/vanzatori/:id/comisioane-aplicate",
    props: true,
    name: "vendors.charges.index",
    component: () =>
      import(/* webpackChunkName: "vendors.charges.index" */ "../views/VendorsChargesIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Vanzatori", href: "/vanzatori", current: false },
          {
            name: `Vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}`,
            current: false,
          },
          {
            name: `Comisioane aplicate vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}/comisioane-aplicate`,
            current: true,
          },
        ];
      },
    },
  },

  {
    path: "/vanzatori/:id/retrageri",
    props: true,
    name: "vendors.withdrawals.index",
    component: () =>
      import(/* webpackChunkName: "vendors.withdrawals.index" */ "../views/VendorsWithdrawalsIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Vanzatori", href: "/vanzatori", current: false },
          {
            name: `Vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}`,
            current: false,
          },
          {
            name: `Retrageri vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}/retrageri`,
            current: true,
          },
        ];
      },
    },
  },
  {
    path: "/vanzatori/:id/retrageri/adauga",
    props: true,
    name: "vendors.withdrawals.create",
    component: () =>
      import(/* webpackChunkName: "vendors.withdrawals.create" */ "../views/VendorsWithdrawalsCreate.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Vanzatori", href: "/vanzatori", current: false },
          {
            name: `Vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}`,
            current: false,
          },
          {
            name: `Retrageri vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}/retrageri`,
            current: false,
          },
          {
            name: `Adauga retragere`,
            href: `/vanzatori/${route.params.id}/retrageri/adauga`,
            current: true,
          },
        ];
      },
    },
  },

  {
    path: "/vanzatori/:id/comisioane",
    props: true,
    name: "vendors.fees.index",
    component: () =>
      import(/* webpackChunkName: "vendors.fees.index" */ "../views/VendorsFeesIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Vanzatori", href: "/vanzatori", current: false },
          {
            name: `Vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}`,
            current: false,
          },
          {
            name: `Comisioane vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}/comisioane`,
            current: true,
          },
        ];
      },
    },
  },
  {
    path: "/vanzatori/:id/comisioane/adauga",
    props: true,
    name: "vendors.fees.create",
    component: () =>
      import(/* webpackChunkName: "vendors.fees.create" */ "../views/VendorsFeesCreate.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Vanzatori", href: "/vanzatori", current: false },
          {
            name: `Vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}`,
            current: false,
          },
          {
            name: `Comisioane vanzator #${route.params.id}`,
            href: `/vanzatori/${route.params.id}/comisioane`,
            current: false,
          },
          {
            name: `Adauga comision`,
            href: `/vanzatori/${route.params.id}/comisioane/adauga`,
            current: true,
          },
        ];
      },
    },
  },

  {
    path: "/intrari-registru",
    name: 'ledger.entries.index',
    component: () =>
      import(/* webpackChunkName: "vendors.fees.create" */ "../views/LedgerEntriesIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: () => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Intrari registru", href: "/intrari-registru", current: true },
        ]
      }
    }
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/Forbidden.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.resolve({
  name: "notFound",
  params: { pathMatch: ["404"] },
}).href; // '/not/found'

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.check()) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (!authService.check()) {
      next();
    } else {
      next({ name: "dashboard.show" });
    }
  } else {
    next(); //make sure to always call next!
  }
});

export default router;
