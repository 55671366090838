import axios from "axios";
import router from "@/router";
import authService from "../services/authService";

const api = {
    config() {
        let headers = {
            Accept: "application/json",
        };
        if (this.token()) headers.Authorization = `Bearer ${this.token()}`;

        return {
            headers: headers,
        };
    },

    token() {
        if (
            localStorage.getItem("token") === null ||
            localStorage.getItem("token") === "undefined"
        ) {
            return null;
        }

        return localStorage.getItem("token");
    },

    get(url, config = null) {
        return new Promise((resolve, reject) => {
            axios
                .get(url, config ?? this.config())
                .then((response) => {
                    this.onSuccess();
                    resolve(response.data)
                })
                .catch((error) => {
                    this.onError(error);
                    reject(error);
                });
        });
    },

    post(url, data = null, config = null) {
        return new Promise((resolve, reject) => {
            axios
                .post(url, data, config ?? this.config())
                .then((response) => {
                    this.onSuccess();
                    resolve(response.data)
                })
                .catch((error) => {
                    this.onError(error);
                    reject(error);
                });
        });
    },

    put(url, data = null, config = null) {
        return new Promise((resolve, reject) => {
            axios
                .put(url, data, config ?? this.config())
                .then((response) => {
                    this.onSuccess();
                    resolve(response.data)
                })
                .catch((error) => {
                    this.onError(error);
                    reject(error);
                });
        });
    },

    delete(url) {
        return new Promise((resolve, reject) => {
            axios
                .delete(url, this.config())
                .then((response) => {
                    this.onSuccess();
                    resolve(response.data)
                })
                .catch((error) => {
                    this.onError(error);
                    reject(error);
                });
        });
    },

    onSuccess(response) {
        this.response = response;
    },

    onError(error) {
        this.error = error;
        switch (error.response.status) {
            case 401: {
                authService.clearSession();
                router.push('/login');
                break;
            }

            case 403: {
                router.push({ name: 'forbidden' });
                break
            }
        }
    },

    // checkCsrf() {
    //     return Cookies.checkCookie('XSRF-TOKEN');
    // },

    // getCsrf() {
    //     return Cookies.getCookie('XSRF-TOKEN');
    // },

    // csrf() {
    //     return new Promise((resolve, reject) => {
    //         axios.get(CSRF, this.config())
    //             .then(response => resolve(response))
    //             .catch(error => reject(error))
    //     })
    // },

    // withCsrf() {
    //     return new Promise((resolve, reject) => {
    //         if (this.checkCsrf())
    //             resolve(this.getCsrf());
    //         else {
    //             axios.get(CSRF, this.config())
    //                 .then(response => resolve(response))
    //                 .catch(error => reject(error))
    //         }
    //     });
    // }
}

export default api;
