export const API = process.env.VUE_APP_API;

//auth
export const LOGIN = API + "login";
export const LOGOUT = API + "logout";
export const REGISTER = API + "register";
export const PASSWORDS = API + "passwords";

//stats
export const STATS = API + "admin/stats";

//vendors
export const VENDOR_STATUSES = API + "vendor_statuses";
export const VENDORS = API + "vendors";
export const VENDORS_SHOW = API + "vendors/:vendor";
export const VENDORS_CHARGES = API + "vendors/:vendor/charges";
export const VENDORS_BALANCE = API + "vendors/:vendor/balance";
export const VENDORS_WITHDRAWALS = API + "vendors/:vendor/withdrawals";
export const VENDORS_WITHDRAWALS_SHOW = API + "vendors/:vendor/withdrawals";


export const VENDORS_TRANSITIONS = API + "vendors/:vendor/transitions";
export const VENDORS_ATTACHMENTS = API + "vendors/:vendor/attachments";
export const VENDORS_ATTACHMENTS_SHOW = API + "vendors/:vendor/attachments/:attachment";
export const VENDORS_FEES = API + "vendors/:vendor/fees";
export const VENDORS_FEES_SHOW = API + "vendors/:vendor/fees/:fee";

//orders
export const ORDER_STATUSES = API + "order_statuses";
export const ORDERS = API + "orders";
export const ORDERS_SHOW = API + "orders/:order";
export const ORDERS_VENDOR_ORDERS = API + "orders/:order/vendor_orders";
export const ORDERS_TRANSITIONS = API + "orders/:order/transitions";

export const VENDOR_ORDERS = API + "vendor_orders";
export const VENDOR_ORDERS_SHOW = API + "vendor_orders/:vendorOrder";
export const VENDOR_ORDERS_ITEMS = API + "vendor_orders/:vendorOrder/items";
export const VENDOR_ORDERS_PAYMENTS = API + "vendor_orders/:vendorOrder/payments";
export const VENDOR_ORDERS_TRANSITIONS = API + "vendor_orders/:vendorOrder/transitions";

//payment
export const PAYMENT_STATUSES = API + "payment_statuses";
export const PAYMENTS = API + "payments";
export const PAYMENTS_SHOW = API + "payments/:payment";
export const PAYMENTS_VENDOR_PAYMENTS = API + "payments/:payment/vendor_payments";
export const PAYMENTS_TRANSITIONS = API + "payments/:payment/transitions";

export const VENDOR_PAYMENTS = API + "vendor_payments";
export const VENDOR_PAYMENTS_SHOW = API + "vendor_payments/:vendorPayment";
export const VENDOR_PAYMENTS_TRANSITIONS = API + "vendor_payments/:vendorPayment/transitions";

//withdrawal
export const WITHDRAWAL_STATUSES = API + "withdrawal_statuses";
export const VENDOR_WITHDRAWALS = API + "vendor_withdrawals";
export const VENDOR_WITHDRAWALS_SHOW = API + "vendor_withdrawals/:vendorWithdrawal";

//fee
export const FEE_TYPES = API + "fee_types";
export const FEE_APPLICABILITIES = API + "fee_applicabilities";
export const FEES = API + "fees";
export const FEES_SHOW = API + "fees/:fee";

//archived rfqs
export const ARCHIVED_RFQS = API + "archived_rfqs";

// ledger entries 
export const LEDGER_ENTRIES = API + "ledger_entries";

// accounts
export const ACCOUNTS_BALANCE = API + "accounts/:account/balance";
