import { LOGIN, LOGOUT, REGISTER } from "../endpoints";
import api from "../api";

export default {
    register(data) {
        return api.post(REGISTER, data);
    },

    login(data) {
        return api.post(LOGIN, data);
    },

    logout(data) {
        return api.post(LOGOUT, data);
    }
}