import api from "../api/api";
import authApi from "../api/resources/authApi";

export default {
  clearSession() {
    // localStorage.removeItem("user");
    localStorage.removeItem("token");
  },

  check() {
    return api.token() !== null;
  },

  saveCredentials(data) {
    localStorage.setItem("token", data.access_token);
  },

  register(data) {
    return new Promise((resolve, reject) => {
      authApi.register(data)
        .then((response) => {
          this.saveCredentials(response.data);
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  },

  login(data) {
    return new Promise((resolve, reject) => {
      authApi.login(data)
        .then((response) => {
          this.saveCredentials(response.data);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      authApi.logout(null)
        .then((response) => {
          localStorage.clear();
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.clearSession();
            }
          }
          reject(error);
        });
    });
  },
}